import clsx from 'clsx';
import React from 'react';

import Button from 'venue/components/shared/Button/Button';

import css from './ValueProps.module.css';

const ValuePropSection = () => {
  return (
    <section className={css.wrapper}>
      <header className={css.header}>
        <h2>Here's how it works</h2>
      </header>
      <div className={clsx(css.journeyBubbleWrapper)}>
        <div className={clsx(css.journeyBubble)}>
          <h3>Build a profile to list your event space</h3>
          <p>
            If you want to host private events at your bar, restaurant, gallery, or other party
            spot, create a profile to feature your space. Include key details about the cost,
            capacity, menu, and more.
          </p>
        </div>
      </div>
      <div className={clsx(css.journeyBubbleWrapper)}>
        <div className={clsx(css.journeyBubble)}>
          <h3>Party planners search our platform</h3>
          <p>
            Venue makes it easy for people to discover the ideal space for their next event. Here,
            they can narrow in the best option whether they’re throwing a 20-person birthday bash, a
            holiday soirée with the whole office, or a game watch with some close friends.
          </p>
        </div>
      </div>
      <div className={clsx(css.journeyBubbleWrapper)}>
        <div className={clsx(css.journeyBubble)}>
          <h3>Venue helps handle the details </h3>
          <p>
            Our platform is a one-stop-shop for private event booking, enabling you to manage
            calendars, costs, payment and more in one secure space.
          </p>
        </div>
      </div>
      <div className={clsx(css.journeyBubbleWrapper)}>
        <div className={clsx(css.journeyBubble)}>
          <h3>First, Philly. And then, the world! </h3>
          <p>
            We are focusing first on Philadelphia as we set out to revolutionize the private event
            space scene. Eventually, Venue will be ready to expand we craft the ultimate 'Airbnb'
            equivalent tailored to everyone’s gatherings!
          </p>
          <div className={css.requestAccess}>
            <Button onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              Request early access
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ValuePropSection;
