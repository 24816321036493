import { RadioGroup } from '@mui/material';
import axios from 'axios';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { Form, FormSpy } from 'react-final-form';

import { FieldRadioButton, FieldTextInput, PrimaryButton } from 'share-tribe/components';
import * as validators from 'share-tribe/util/validators';

import css from './StealthSubscribe.module.css';

const emailRequired = validators.required('An email is required.');
const emailValid = validators.emailFormatValid('A valid email is required.');
const firstNameRequired = validators.required('A first name is required.');
const lastNameRequired = validators.required('A last name is required.');
const venueNameRequired = validators.required('A Venue name is required.');

interface FormData {
  email: string;
  firstname: string;
  lastname: string;
  marketplace_side: 'List Venue' | 'Book Venue';
  venue_name?: string;
}

const portalId = process.env.REACT_APP_HUBSPOT_PORTAL_ID;
let formGuid = process.env.REACT_APP_HUBSPOT_USER_STEALTH_FORM_GUID;
const hubspotutk = Cookies.get('hubspotutk');

export const StealthSubscribeForm = () => {
  const [showVenueName, setShowVenueName] = useState(false);
  const [response, setResponse] = useState<
    { status: 'success' | 'error'; message?: string } | undefined
  >(undefined);

  const onSubmit = async (data: FormData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const fields = [
      {
        name: 'firstname',
        value: data.firstname,
      },
      {
        name: 'lastname',
        value: data.lastname,
      },
      {
        name: 'email',
        value: data.email,
      },
    ];

    if (showVenueName && data.venue_name) {
      formGuid = process.env.REACT_APP_HUBSPOT_VENUE_STEALTH_FORM_GUID;
      fields.push({
        name: 'venue_name',
        value: data.venue_name,
      });
    }

    await axios
      .post(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
        {
          portalId,
          formGuid,
          context: {
            hutk: hubspotutk,
            pageName: 'Splash',
          },
          fields,
        },
        config
      )
      .then((response) => {
        setResponse({ status: 'success', message: response.data.inlineMessage });
      })
      .catch((error) => {
        const message =
          error.code === 'ERR_BAD_REQUEST'
            ? 'There was an issue submitting your information. Please try again.'
            : error.message;
        setResponse({ status: 'error', message });
      });
  };

  return (
    <div className={css.root}>
      {response ? (
        <div
          className={clsx(css['response-message'], {
            [css['response-message--success']]: response.status === 'success',
            [css['response-message--error']]: response.status === 'error',
          })}
        >
          <h3>{response.status === 'success' ? 'Party time 🎉' : 'Sorry!'}</h3>
          <div dangerouslySetInnerHTML={{ __html: response.message ?? '' }}></div>
        </div>
      ) : null}
      {response?.status !== 'success' ? (
        <Form
          onSubmit={onSubmit}
          initialValues={{ marketplace_side: 'Book Venue' }}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <FormSpy
                  subscription={{ values: true, modified: true }}
                  onChange={(state) => {
                    if (state.modified?.marketplace_side) {
                      setShowVenueName(state.values.marketplace_side === 'List Venue');
                    }
                  }}
                />
                <h3 className={css['form-title']}>Come join us!</h3>
                <FieldTextInput
                  id="firstName"
                  className={css['form-field']}
                  placeholder="Rob"
                  type="text"
                  label="First name *"
                  name="firstname"
                  validate={validators.composeValidators(firstNameRequired)}
                />
                <FieldTextInput
                  id="lastName"
                  className={css['form-field']}
                  placeholder="McElhenney"
                  type="text"
                  label="Last name *"
                  name="lastname"
                  validate={validators.composeValidators(lastNameRequired)}
                />
                <FieldTextInput
                  id="email"
                  label="Email *"
                  name="email"
                  type="email"
                  className={css['form-field']}
                  placeholder="rob@itsalwayssunny.com"
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
                <label className={css['radio-button__label']} htmlFor="marketplace_side">
                  I am looking to:
                </label>
                <RadioGroup>
                  <FieldRadioButton
                    label="Book a venue"
                    id="marketplaceSide_book"
                    name="marketplace_side"
                    value="Book Venue"
                  />
                  <FieldRadioButton
                    label="List a venue"
                    id="marketplaceSide_list"
                    name="marketplace_side"
                    value="List Venue"
                  />
                </RadioGroup>

                {showVenueName ? (
                  <FieldTextInput
                    id="venueName"
                    className={css['form-field']}
                    type="text"
                    label="Venue name *"
                    name="venue_name"
                    validate={
                      showVenueName ? validators.composeValidators(venueNameRequired) : undefined
                    }
                  />
                ) : null}
                <PrimaryButton
                  className={css['submit-button']}
                  type="submit"
                  inProgress={submitting}
                >
                  Get early access
                </PrimaryButton>
              </form>
            );
          }}
        />
      ) : null}
    </div>
  );
};
