import clsx from 'clsx';
import React from 'react';

import css from './CakeLayer.module.css';

type CakeLayerColorOptions = 'sake' | 'spirit' | 'gradient--mimosa-violet';

type CakeLayerImageOption = 'random' | '1' | '2' | '3' | '4' | '5';

type CakeLayerContentSize = 'small' | 'medium' | 'full';

interface CakeLayerProps {
  children: React.ReactNode;
  imageOption?: CakeLayerImageOption;
  colorOption?: CakeLayerColorOptions;
  contentSize?: CakeLayerContentSize;
}

const numRandomImages = 5;

const CakeLayerContainer = ({
  children,
  imageOption,
  colorOption,
  contentSize = 'full',
}: CakeLayerProps) => {
  const isGradientBackground = colorOption?.includes('gradient');
  const isSolidBackground = !colorOption?.includes('gradient');

  const isRandomImageBackground = imageOption === 'random';
  const backgroundImageIndex = isRandomImageBackground
    ? Math.floor(Math.random() * numRandomImages) + 1
    : undefined;

  const isImageOption = imageOption !== undefined && imageOption !== 'random';

  return (
    <div
      className={clsx(css.wrapper, {
        [css[`solid--${colorOption}`]]: isSolidBackground && colorOption,
        [css[`${colorOption}`]]: isGradientBackground,
        [css.image]: isRandomImageBackground || isImageOption,
        [css[`image-${imageOption}`]]: isImageOption,
        [css[`image-${backgroundImageIndex}`]]: isRandomImageBackground
          ? backgroundImageIndex
          : undefined,
      })}
    >
      <div className={clsx(css[`contentSize--${contentSize}`])}>{children}</div>
    </div>
  );
};

export default CakeLayerContainer;
