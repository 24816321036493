import React from 'react';

import { StealthSubscribeForm } from 'venue/forms/StealthSubscribe/StealthSubscribe.form';

import css from './Splash.module.css';

const SplashHero = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <h1 className={css.title}>
          Book your ideal party space&mdash;no matter what you're celebrating
        </h1>
        <p className={css.subtitle}>
          Venue helps you discover and secure the perfect spot for your next event.
        </p>
      </div>
      <div>
        <StealthSubscribeForm />
      </div>
    </div>
  );
};

export default SplashHero;
