import React from 'react';
import { LayoutSingleColumn, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import SplashHero from 'venue/components/heroes/Splash/Splash.hero';
import FooterSection from 'venue/layout/sections/Footer/Footer.section';
import CakeLayerContainer from 'venue/components/containers/CakeLayer/CakeLayer.container';
import ValuePropSection from 'venue/layout/sections/ValueProps/ValueProps';
const ControlledLandingPageComponent = (props) => {
  const { scrollingDisabled } = props;

  return (
    <Page
      title={'Venue'}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: 'Venue',
        description: 'Venue',
      }}
    >
      <LayoutSingleColumn topbar={<TopbarContainer />}>
        <CakeLayerContainer colorOption="gradient--mimosa-violet" contentSize="xl">
          <SplashHero />
        </CakeLayerContainer>
        <CakeLayerContainer imageOption="5">
          <ValuePropSection />
        </CakeLayerContainer>
      </LayoutSingleColumn>
      <FooterSection />
    </Page>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state.user;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ControlledLandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(ControlledLandingPageComponent);

export default ControlledLandingPage;
